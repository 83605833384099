import { tailwindConfig } from "../../../tailwind-config/tailwind.config";

export const getTailwindColorName = (hexColor: string): string => {
	const colorKeys = Object.keys(tailwindConfig?.theme.colors);
	const colorCategories = colorKeys.flatMap((color) => {
		const colorCategory = tailwindConfig.theme.colors[color];
		return typeof colorCategory === "string"
			? null
			: Object.entries(colorCategory)?.map((item) => {
					const hex: string = item[1].toString();
					return [hex, `${color}-${item[0]}`];
				});
	});
	const colorsFlat = colorCategories
		.filter((item) => item !== null)
		.flatMap((item) => {
			return [{ [item[0]]: item[1] }];
		});

	const colorName = colorsFlat.find((item) => item[hexColor]);
	return colorName[hexColor] || "gray-200";
};
