import { DateTime } from "luxon";
import { Keg } from "types";

export const sortKegsByReceivedDate = (kegs: Keg[]): Keg[] => {
	return kegs.sort((a, b) => {
		const dateA: number = DateTime.fromISO(a.receivedDate).toMillis();
		const dateB: number = DateTime.fromISO(b.receivedDate).toMillis();
		return dateB - dateA;
	});
};
