import { Address } from "types";
import { statesUS } from "./constants";

export enum AddressFormat {
	Street,
	CityState,
	StreetCityState,
	Complete,
}

const parseAddress = (address: Address, format?: AddressFormat): string => {
	if (!address) return "--";
	const state: string =
		Object.values(statesUS).find((item) => item?.label === address?.state)
			?.code || address?.state;
	if (format === AddressFormat.CityState) return `${address?.city}, ${state}`;
	if (format === AddressFormat.Complete)
		return Object.values({ ...address, state }).join(",");
	if (format === AddressFormat.Street)
		return `${address?.street}, ${address?.street2}`;

	return `${address?.street}, ${address?.city}, ${state}`;
};

export default parseAddress;
