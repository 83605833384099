import React from "react";

interface IUseAutoFocus {
	ref: React.MutableRefObject<HTMLInputElement>;
}

/**
 * Autoselect an input
 *
 * @param {boolean} [selectInputContents=false]
 * @returns {IUseAutoFocus}
 */
const useAutoFocus = (selectInputContents = false): IUseAutoFocus => {
	const selectContents: React.MutableRefObject<boolean> =
		React.useRef(selectInputContents);
	const inputRef = React.useRef(null);

	React.useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
			if (selectContents.current) {
				inputRef.current.select();
			}
		}
	}, []);

	return { ref: inputRef };
};

export default useAutoFocus;
