import { DateTime, Settings } from "luxon";
import { statesUS } from "./constants";

import { Asset } from "types";
// import { statesUS } from './constants'

Settings.throwOnInvalid = true;

// export const updateObjProp = (obj, value, path) => {
//     let i
//     path = path.split('.')
//     for (i = 0; i < path.length - 1; i++) obj = obj[path[i]]

//     obj[path[i]] = value

//     return obj
// }

export function updateObjProp(obj, value, path) {
	const a = path.split(".");
	let o = obj;
	while (a.length - 1) {
		const n = a.shift();
		if (!(n in o)) o[n] = {};
		o = o[n];
	}
	o[a[0]] = value;
}

/**
 * Parse a datetime string into readable text.
 * Defaults to show only date with option to show time as well.
 *
 * @param {String} dateString
 * @param {Boolean} time
 * @returns
 */
export const parseDateAsText = (dateString: string, time = true) => {
	const localDateString =
		dateString +
		" " +
		new Date().toLocaleTimeString("en", { timeZoneName: "short" });

	const dateObj = new Date(localDateString);

	// return simple date string for now
	return (
		`${dateObj.toLocaleDateString()}` +
		(time === true ? `, ${dateObj.toLocaleTimeString()}` : "")
	);
};

// /**
//  *
//  * @param {object} obj - object to extract value from
//  * @param {string} keys  - period delimited property keys e.g. "item.title"
//  * @returns
//  */
// export const propertyByString = (obj, keys) => {
//     keys = keys.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
//     keys = keys.replace(/^\./, '') // strip a leading dot
//     const a = keys.split('.')
//     for (let i = 0, n = a.length; i < n; ++i) {
//         const k = a[i]
//         if (typeof obj === 'object' && obj !== null && k in obj) {
//             obj = obj[k]
//         } else {
//             return false
//         }
//     }
//     return obj
// }

/**
 * Checks if number is within a defined min and max range
 *
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @returns {bool}
 */
export const withinRange = (value, min, max) => {
	return value >= min && value <= max;
};

export const flattenObject = (oldObject, delimiter = ".") => {
	const newObject = {};

	flattenHelper(oldObject, newObject, "");

	return newObject;

	function flattenHelper(currentObject, newObject, previousKeyName) {
		for (const key in currentObject) {
			const value = currentObject[key];

			if (value.constructor !== Object) {
				if (previousKeyName == null || previousKeyName === "") {
					newObject[key] = value;
				} else {
					if (key == null || key === "") {
						newObject[previousKeyName] = value;
					} else {
						newObject[previousKeyName + delimiter + key] = value;
					}
				}
			} else {
				if (previousKeyName == null || previousKeyName === "") {
					flattenHelper(value, newObject, key);
				} else {
					flattenHelper(value, newObject, previousKeyName + delimiter + key);
				}
			}
		}
	}
};

export const snakeToPascal = (string) => {
	if (!string) return null;

	return string
		.split("/")
		.map((snake) =>
			snake
				.split("_")
				.map((substr) => substr.charAt(0).toUpperCase() + substr.slice(1))
				.join(" "),
		)
		.join("/");
};

export const getStatesByCountry = (countryCode) => {
	switch (countryCode) {
		case "US":
			return Object.values(statesUS).map((state) => ({
				label: state.label,
				value: state.code,
			}));

		default:
			return [{ label: null, value: null }];
	}
};

// Custom map function to render label array for given input object array
export const customMapByLabel = (items) => items.map((item) => item.label);

// Custom map function to render label array for given input object array
export const customMapByValue = (items) => items.map((item) => item.value);

/**
 *
 * @param {Object} asset - The asset object
 * @param {Int} index - index of the asset you are looking for
 * @returns
 */
export const getAssetPath = (asset, index, size = "large") => {
	const cdnPath =
		process.env.cdnDomain ||
		process.env.NEXT_PUBLIC_CDN_DOMAIN ||
		"https://d3szackftvnutw.cloudfront.net";

	if (asset && asset.length > 0) {
		if (asset[index] && asset[index].path) {
			const path =
				asset[index].path.indexOf("/") !== 0
					? `/${asset[index].path}`
					: asset[index].path;
			const suffix = path.search(/\.[a-zA-Z0-9]{2,4}/) ? "" : `-${size}.png`;

			return `${cdnPath}${path}${suffix}`;
		} else {
			return false;
		}
	} else return false;
};

export const getAssetData = ({
	asset,
	index = 0,
	size = "large",
}: {
	asset: Asset[];
	index?: number;
	size?: string;
}): { path: string; alt: string } => {
	const cdnPath =
		process.env.cdnDomain ||
		process.env.NEXT_PUBLIC_CDN_DOMAIN ||
		"https://d3szackftvnutw.cloudfront.net";

	if (asset && asset.length > 0 && asset[index] && asset[index].path) {
		const path =
			asset[index].path.indexOf("/") !== 0
				? `/${asset[index].path}`
				: asset[index].path;
		const suffix = path.search(/\.[a-zA-Z0-9]{2,4}/) ? "" : `-${size}.png`;

		return { path: `${cdnPath}${path}${suffix}`, alt: asset[index]?.alt || "" };
	}
	return { path: "/brewlogix-favicon--lg.png", alt: "BrewLogix Pint" };
};

export const formatKegCost = (value) => {
	if (!value || value === "$") return "0";

	let stringValue = value;

	// Convert value type to number
	if (typeof value !== "string") stringValue = value.toString();

	// Remove $ sign
	stringValue = stringValue.slice(1);

	// Expected range
	if (parseFloat(stringValue) > 0 && parseFloat(stringValue) < 10000) {
		if (stringValue.slice(-1) === "." || parseFloat(stringValue) % 1 === 0) {
			return stringValue;
		}
		return parseFloat(stringValue).toFixed(2);
	}

	// Integer
	if (parseFloat(stringValue) % 1 === 0) {
		return stringValue.slice(0, 4);
	}

	return stringValue;
};

/**
 *
 * @param {String} size - pour sizes as an enum (_8) or int (8)
 * @returns {label: String, value: String}
 */
export const parsePourSize = (size) => {
	return {
		label: `${size.replace("_", "")} oz`,
	};
};

/**
 * @param {String} date - date that you want to compare to today
 * @returns {Int} - number of days between today and date
 */
export const getDaysFromNow = (date) => {
	if (date) {
		const now = DateTime.local();
		const targetDate = DateTime.fromISO(date);
		const diff = now.diff(targetDate, ["days", "hours"]).toObject();
		return diff.days;
	}
};

/**
 * isStrongPassword()
 *
 * Ensure password meets min security standards:
 * - 8+ characters
 * - Uppercase letter
 * - Lowercase letter
 * - 1+ Number
 * - 1+ special character !@#$%^&*()+
 *
 * @param {string} enteredPassword - password to check
 * @param {boolean} feedback - whether to return detailed feedback or just a boolean
 * @returns {boolean | object} - true if password is strong | object outlining requirement pass/fail status as props
 */
export const isStrongPassword = (enteredPassword, feedback = false) => {
	if (enteredPassword && feedback === false)
		return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[[\]()'":;-_+=><!@#$%^&*|])(?=.{8,})/g.test(
			enteredPassword,
		);
	const passwordFedback = {
		length: /(?=.{8,})/g.test(enteredPassword),
		uppercase: /(?=.*[A-Z])/g.test(enteredPassword),
		lowercase: /(?=.*[a-z])/g.test(enteredPassword),
		number: /(?=.*[0-9])/g.test(enteredPassword),
		specialCharacter: /(?=.*[!@#$%^&*()_\-+=;:'"><.,{}()[\]?/~`])/g.test(
			enteredPassword,
		),
		valid:
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_\-+=;:'"><.,{}()[\]?/~`])(?=.{8,})/g.test(
				enteredPassword,
			),
	};

	return passwordFedback;
};

/**
 * Parse Relative Dates
 *
 * Generate date string relative to a start date e.g. tomorrow
 *
 * @param {string} date - Date to measure
 * @param {string} compareDate - Relative date
 * @returns {string}
 */
export const parseRelativeDates = (date, compareDate, asWord = true) => {
	const dateObj = new Date(date);

	const today = compareDate ? new Date(compareDate) : new Date();

	const daysBetween = Math.round((+dateObj - +today) / (1000 * 60 * 60 * 24));

	if (asWord === false) {
		return daysBetween;
	}
	let dateLabel = "";
	// Change Date format based on # days between dates
	// @TODO: Account for hours open past midnight
	switch (true) {
		case withinRange(daysBetween, 2, 6):
			dateLabel = dateObj.toLocaleString("en-us", { weekday: "long" });
			break;
		case daysBetween >= 1 && daysBetween <= 2:
			dateLabel = "Tomorrow";
			break;
		case (daysBetween < 1 && daysBetween > 0) || daysBetween === 0:
			dateLabel = "Today";
			break;
		case daysBetween < 0 && daysBetween > -1:
			dateLabel = "Yesterday";
			break;
		default:
			dateLabel = `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear()}`;
			break;
	}
	return dateLabel;
};

export const getAverageSRM = (min, max) => {
	const average = (min + max) / 2;
	return Math.round(average);
};

/**
 *
 * @param {string} hour - A time in 24hr format
 * @returns {string} - A time in 12hr format
 */
export const formatTime = (hour) => {
	const hourNum = parseInt(hour.split(":")[0], 10);
	const minuteNum = hour.split(":")[1];
	const suffix = hourNum >= 12 ? "pm" : "am";
	hour = `${((hourNum + 11) % 12) + 1}:${minuteNum}${suffix}`;
	return hour;
};
