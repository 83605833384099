import _ from "lodash";

function parseSubPropertyData<T = unknown>(
	key: string,
	value: string | number,
	formData: T,
) {
	const newDataLodash = _.cloneDeep(formData as {});
	_.set(newDataLodash, key, value);

	return newDataLodash as T;
}

export default parseSubPropertyData;
