import { DateTime } from "luxon";

const stripTime = (timestamp: DateTime): DateTime =>
	timestamp.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

/**
 * Returns the number of days between today and the given timestamp.
 * @param timestamp The timestamp to compare to.
 * @param absolute default `true` If true, the result will always be non-negative.
 */
export const daysFromToday = (
	timestamp: DateTime,
	absolute: boolean = true,
): number => {
	const now = stripTime(DateTime.now());
	const newTimestamp = stripTime(timestamp);
	const actualDays = Math.round(now.diff(newTimestamp, "days", {}).days);
	if (absolute) {
		return Math.abs(actualDays);
	} else {
		return -actualDays;
	}
};
