import { CompositeProduct } from "types";

export const getProductsByStatus = (
	products: CompositeProduct[],
): { verified: CompositeProduct[]; unverified: CompositeProduct[] } => {
	const verified = products?.filter((item) => item.isVerified);
	const unverified = products?.filter((item) => !item.isVerified);

	return { verified, unverified };
};
