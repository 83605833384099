import { Keg, KegStatus } from "types";
import { calculateFillLevel } from "../vessel";

const transformedReadingOnTappedKeg = (keg: Keg) =>
	keg.kegStatus === KegStatus.Tapped
		? keg.assignedTapSensor?.transformedReading
		: undefined;

export const getKegFillLevel = (keg: Keg): number =>
	calculateFillLevel(
		transformedReadingOnTappedKeg(keg) || keg.currentFluidOz || 0,
		keg.initialFluidOz || keg?.vessel?.suggestedTotalVolume,
	);
