"use client";
import { useState } from "react";

export const usePaginatedData = <T>(data: T[], pageSize: number = 10) => {
	const [currentPage, setCurrentPage] = useState(0);

	const totalPages = Math.ceil(data.length / pageSize);

	const currentPageData = data.slice(
		currentPage * pageSize,
		(currentPage + 1) * pageSize,
	);

	const pageUp = () => {
		if (currentPage < totalPages - 1) {
			setCurrentPage(currentPage + 1);
		}
	};

	const pageDown = () => {
		if (currentPage > 0) {
			setCurrentPage(currentPage - 1);
		}
	};

	return {
		currentPageData,
		pageUp,
		pageDown,
		currentPage: currentPage + 1,
		totalPages,
	};
};
