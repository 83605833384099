import { DateTime } from "luxon";
import { daysFromToday } from "../daysFromToday/daysFromToday";

/**
 * Gives the string to be displayed as the kick date, given the expected kick date.
 * If the kick date is `undefined` then it returns '--'.
 * @param expectedKickDate ISO string of the expected kick date
 * @returns The expected kick date relative to today
 */
export const tapCardKickDateDisplay = (
	expectedKickDate: string | undefined,
) => {
	if (!expectedKickDate) return "--";
	const lxDate = DateTime.fromISO(expectedKickDate);
	const numDays = daysFromToday(lxDate);
	if (numDays === 0 || lxDate < DateTime.now()) return "Today";
	if (numDays === 1) return "Tomorrow";
	if (numDays < 7) return lxDate.weekdayLong;
	return "7+ Days";
};
