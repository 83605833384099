import { KegStatus } from "types";
import { isLowKeg } from "./isLowKeg/isLowKeg";
import { ozLeft } from "./ozLeft/ozLeft";

export const parseOzLeft = (
	transformedReading: number | undefined,
	currentFlOz: number | undefined,
	kegStatus: KegStatus,
	noOz: boolean = false,
): string => {
	const remainingOz = ozLeft(transformedReading, currentFlOz);
	if (isLowKeg(transformedReading, currentFlOz, kegStatus)) return "Low";
	if (noOz) return `${Math.max(0, Math.floor(remainingOz))}`;
	else return `${Math.max(0, Math.floor(remainingOz)).toLocaleString()}oz`;
};
