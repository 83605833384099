import { DateTime } from "luxon";

const DEFAULT_FRESHNESS_DURATION = 120;

type KegLike = {
	bornOnDate?: string;
	expirationDate?: string;
	product?: {
		style?: {
			kegFreshnessDuration?: number;
		};
	};
};
/**
 * Determines the best by date for a keg, for use in the freshness pill.
 * Note that BrewLogix REQUIRES a bornOnDate to be present before we give a best by date.
 * This is a business rule, not a technical limitation.
 */
export const bestByOrInferred = (keg: KegLike): DateTime | null => {
	if (!keg.bornOnDate) return null;
	if (keg.expirationDate) return DateTime.fromISO(keg.expirationDate);
	const freshnessDuration =
		keg.product?.style?.kegFreshnessDuration || DEFAULT_FRESHNESS_DURATION;
	return DateTime.fromISO(keg.bornOnDate).plus({ days: freshnessDuration });
};
