import { BrewTypes } from "types";

// @TODO: manage this with Hypertune feature flags
const supportedBrewTypes: BrewTypes[] = [
	BrewTypes.Beer,
	BrewTypes.Cider,
	BrewTypes.Coffee,
	BrewTypes.Kombucha,
	BrewTypes.NonAlcoholic,
	BrewTypes.Seltzer,
];

export default supportedBrewTypes;
