import { TimeZone } from "types";
import { parseEnumLabel } from "../string";

const parseTimezoneEnum = (timezone: TimeZone): string => {
	if (!timezone) return "";
	const label = parseEnumLabel(String(timezone));
	return label.replace(/\s/g, "_");
};

export default parseTimezoneEnum;
