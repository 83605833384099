import { Keg } from "types";
import { getKegFillLevel } from "../inventory";
import { isLowKeg } from "../inventory/isLowKeg/isLowKeg";

const parsePercentLeft = (keg: Keg): string => {
	if (!keg) return "";
	if (
		isLowKeg(
			keg.assignedTapSensor?.transformedReading,
			keg.currentFluidOz,
			keg.kegStatus,
		)
	) {
		return "Low";
	}

	const fillLevel = getKegFillLevel(keg);
	return `${fillLevel}%`;
};

export default parsePercentLeft;
