import { Keg, KegStatus } from "types";
import { calculateFillLevel } from "../vessel";

const getPercentLeftNumber = (keg: Keg): number => {
	return calculateFillLevel(
		keg?.kegStatus === KegStatus.Tapped
			? keg?.assignedTapSensor?.transformedReading
			: keg?.currentFluidOz,
		keg?.initialFluidOz || keg?.vessel?.suggestedTotalVolume,
	);
};

export default getPercentLeftNumber;
