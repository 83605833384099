import { IOption } from "types/src/utility/ui";
import { countries } from "./countries";
const countryOptions = ({
	floatToTop,
}: { floatToTop: string[] }): IOption<string>[] => {
	const countryOptions = countries
		.sort((a, b) => {
			if (floatToTop?.includes(a.code)) {
				return -1;
			}
			return 1;
		})
		.map((country) => ({
			label: country.name,
			value: country.code,
		}));

	return countryOptions;
};

export default countryOptions;
