import { DateTime } from "luxon";

/**
 * Returns a string representing the time since the data was last updated.
 * It may be in the format of 'x hours ago', 'x minutes ago', 'x seconds ago', or simply a date.
 * @param timeStamp The `luxon.DateTime` object representing the last time the data was updated
 */
export const lastUpdated = (timeStamp: DateTime) => {
	const now = DateTime.now();
	const diff = now.diff(timeStamp, ["hours", "minutes", "seconds"]);

	if (diff.hours >= 48) {
		return `${timeStamp.toLocaleString(DateTime.DATE_MED)} at ${timeStamp.toLocaleString(DateTime.TIME_SIMPLE)}`;
	} else if (diff.hours >= 24) {
		return `Yesterday at ${timeStamp.toLocaleString(DateTime.TIME_SIMPLE)}`;
	} else if (diff.hours < 24 && diff.hours >= 1) {
		return `${diff.hours + (diff.minutes > 29 ? 1 : 0)}h ago`;
	} else if (diff.minutes < 60 && diff.minutes >= 1) {
		return `${diff.minutes}m ago`;
	} else {
		return `${diff.seconds.toFixed(0)}s ago`;
	}
};
