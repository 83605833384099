import { PasswordRequirements, PasswordStrength } from "types/src/utility/ui";

interface ICheckPasswordRequirements {
	requirements: PasswordRequirements;
	strength: number;
	message: string;
}

const checkPasswordRequirements = (
	password: string,
): ICheckPasswordRequirements => {
	const requirements: PasswordRequirements = {
		hasNumber: /\d/.test(password),
		hasSpecialCharacter: /(?=.*[-+_@#$%^&=*.!, ?()><~`])/.test(password),
		upperAndLowerCase: /(?=.*[a-z])(?=.*[A-Z])/.test(password),
		enoughCharacters: password?.length >= 8,
	};

	const strength: number = Object.values(requirements).filter(
		(req) => req === true,
	)?.length;
	let message: string;
	if (strength === 4) {
		message = PasswordStrength.Strong;
	} else {
		message = strength === 3 ? PasswordStrength.Close : PasswordStrength.Weak;
	}

	return { requirements, message, strength };
};

export default checkPasswordRequirements;
