import { USStates } from "types/src/utility/address";
import { IOption } from "types/src/utility/ui";

const stateOptions = ({ country }: { country: string }): IOption<string>[] => {
	if (country !== "US") return [];

	const stateOptions = Object.keys(USStates).map((key) => ({
		label: USStates?.[key],
		value: String(key),
	}));

	return stateOptions;
};

export default stateOptions;
