export const brewMenuSubscriptionFragment = /* GraphQL */ `{
		brandId
		createdBy
		createdDate
		displays {
			createdBy
			createdDate
			displayTitle
			displayType
			id
			lastUpdatedBy
			menu {
				activeDates
				createdBy
				createdDate
				id
				lastUpdatedBy
				locationId
				ppSubId
				status
				title
				updatedDate
			}
			settings {
				allowPagination
				colorScheme
				columns
				fallbackImage
				orientation
				productImage
				screenMode
			}
			slug
			status
			title
			updatedDate
		}
		hubSpotId
		id
		isActive
		lastUpdatedBy
		locationIds
		menus {
			activeDates
			createdBy
			createdDate
			displays {
				createdBy
				createdDate
				displayTitle
				displayType
				id
				lastUpdatedBy
				settings {
					allowPagination
					colorScheme
					columns
					fallbackImage
					orientation
					productImage
					screenMode
				}
				slug
				status
				title
				updatedDate
			}
			id
			lastUpdatedBy
			locationId
			ppSubId
			status
			title
			updatedDate
		}
		ppSubId
		settings {
			address {
				city
				country
				geo {
					lat
					lon
					title
				}
				postalCode
				state
				street
				street2
			}
			contact {
				email
				phone
			}
		}
		slug
		subscriptionTier
		title
		updatedDate
	}

}`;
